import React from "react";

export default function IconLoupe() {
  return (
    <svg
      width="23.000000"
      height="23.000000"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="XMLID_41_"
        d="M22.71 21.36L16.99 15.64C18.35 13.99 19.16 11.88 19.16 9.58C19.16 4.29 14.86 0 9.58 0C4.29 0 0 4.29 0 9.58C0 14.86 4.29 19.16 9.58 19.16C11.88 19.16 13.99 18.35 15.64 16.99L21.36 22.71C21.73 23.09 22.34 23.09 22.71 22.71C23.09 22.34 23.09 21.73 22.71 21.36ZM1.91 9.58C1.91 5.35 5.35 1.91 9.58 1.91C13.81 1.91 17.24 5.35 17.24 9.58C17.24 13.81 13.81 17.24 9.58 17.24C5.35 17.24 1.91 13.81 1.91 9.58Z"
        fill="#5E5F74"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
