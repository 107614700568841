import React, { useMemo } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconTgCircle from "../../mui/JSXIcons/IconTgCircle";
import { useSelector } from "react-redux";
import ChoseLang from "../../mui/chose_lang/ChoseLang";
import ShowContainer from "../../mui/ShowContainer";

export default function Footer() {
  const { t } = useTranslation();
  const botName = useSelector((state) => state.common.botName);
  const supportChat = useSelector((state) => state.common.supportChat);
  const isTgBot = useSelector((state) => state.common.isTgBot);

  const year = useMemo(() => new Date().getFullYear(), []);
  const logoText = useSelector((state) => state.common.casinoName);

  return (
    <ShowContainer condition={!isTgBot}>
      <footer className="footer">
        <div className="footer__content">
          <span className="footer__copyright">
            © {year} {logoText}
          </span>
          <Link className="footer__agreement" to={"/terms"}>
            {t("Usage agreement")}
          </Link>
          <ShowContainer condition={botName}>
            <a
              className="footer__play-tg"
              target="_blank"
              href={`https://t.me/${botName}`}
              rel="noreferrer">
              <span className="footer__play-tg__title">{t("Play as a bot:")}</span>
              <span className="footer__play-tg__name">
                <IconTgCircle />@{botName}
              </span>
            </a>
          </ShowContainer>

          <ShowContainer condition={supportChat}>
            <a
              className="footer__support-tg"
              target="_blank"
              href={`https://t.me/${supportChat}`}
              rel="noreferrer">
              <span className="footer__support-tg__title">{t("Support: ")}</span>
              <span className="footer__support-tg__name">
                <IconTgCircle />@{supportChat}
              </span>
            </a>
          </ShowContainer>

          <ChoseLang />
        </div>
      </footer>
    </ShowContainer>
  );
}
