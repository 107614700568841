import React from "react";

export default function IconLook() {
  return (
    <svg
      width="21.333496"
      height="28.000000"
      viewBox="0 0 21.3335 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M18.66 9.33L17.33 9.33L17.33 6.66C17.33 2.98 14.34 0 10.66 0C6.98 0 4 2.98 4 6.66L4 9.33L2.66 9.33C1.2 9.33 0 10.53 0 12L0 25.33C0 26.79 1.2 28 2.66 28L18.66 28C20.13 28 21.33 26.79 21.33 25.33L21.33 12C21.33 10.53 20.13 9.33 18.66 9.33ZM6.66 6.66C6.66 4.45 8.45 2.66 10.66 2.66C12.87 2.66 14.66 4.45 14.66 6.66L14.66 9.33L6.66 9.33L6.66 6.66ZM18.66 25.33L2.66 25.33L2.66 12L18.66 12L18.66 25.33ZM10.66 21.33C12.13 21.33 13.33 20.13 13.33 18.66C13.33 17.2 12.13 16 10.66 16C9.2 16 8 17.2 8 18.66C8 20.13 9.2 21.33 10.66 21.33Z"
        fill="#7A7B90"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
