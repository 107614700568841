import React from "react";
import "./game-desk.scss";
import CarouselDesk from "../../../mui/carousel_desk/CarouselDesk";

import GameItem from "../../../mui/game_tem/GameItem";
import ShowContainer from "../../../mui/ShowContainer";

export default function GameDesk({
  title,
  hideFavorites,
  showAllLink,
  gameNavigate = "game",
  items = []
}) {
  return (
    <ShowContainer condition={Array.isArray(items) && items.length}>
      <CarouselDesk title={title} items={items} showAllLink={showAllLink}>
        {items.map((el, indx) => (
          <GameItem
            key={el.game_id || el.id || indx}
            currentGame={el}
            image={el.image}
            author={el.author}
            name={el.name}
            hideFavorites={hideFavorites}
            url={gameNavigate + "/"}
          />
        ))}
      </CarouselDesk>
    </ShowContainer>
  );
}
