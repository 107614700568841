import React, { useState, useMemo } from "react";
import IconArrow from "../../../mui/JSXIcons/IconArrow";
import { useSelector } from "react-redux";
import ShowContainer from "../../../mui/ShowContainer";
import "./mobile-balance.scss";
import useBalanceUpdate from "../../../../hooks/useBalanceUpdate";

export default function MobileBalance({ keyBalance, onBalanceClick, decimal }) {
  const { TITLE_TO_KEY, IMG_TO_KEY } = useBalanceUpdate();

  const balance = useSelector((state) => state.user.balance);

  const [value, setValue] = useState(0);
  const balanceItem = useMemo(() => keyBalance?.[value], [keyBalance, value]);

  const onPrevClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (value == 0) return setValue(keyBalance.length - 1);
    setValue(value - 1);
  };
  const onNextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (value == keyBalance.length - 1) return setValue(0);
    setValue(value + 1);
  };

  const valueBalance = balance?.[balanceItem]?.toFixed(decimal);

  return (
    <ShowContainer condition={balance && balanceItem}>
      <div className="mobile-balance" onClick={() => onBalanceClick(balanceItem)}>
        <div className="mobile-balance__title">{TITLE_TO_KEY[balanceItem]}</div>
        <div className="mobile-balance__controls">
          <button className="mobile-balance__controls__prev" onClick={onPrevClick}>
            <IconArrow className="rotate90" />
          </button>

          <div className="mobile-balance__content">
            <span className="mobile-balance__value">{valueBalance}</span>
            {IMG_TO_KEY[balanceItem]}
          </div>

          <button className="mobile-balance__controls__next" onClick={onNextClick}>
            <IconArrow className="rotate270" />
          </button>
        </div>
      </div>
    </ShowContainer>
  );
}
