import React, { useRef, useState } from "react";
import "./login-modal.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import Segmented from "rc-segmented";
import { DEBUG, SITE_KEY } from "../../../store/enums";
import CusForm from "../../mui/CusForm";
import AuthTg from "../../mui/auth_tg/AuthTg";
import IconMail from "../../mui/JSXIcons/IconMail";
import IconLook from "../../mui/JSXIcons/IconLook";
import CusInput from "../../mui/custom_input/CusInput";
import { API_USER } from "../../../api/user";
import { useLocalStorage } from "usehooks-ts";
import { errorToast, successToast } from "../../mui/Toaster";
import { setInfo, setUserId } from "../../../store/userSlice";
import DefaultModal from "../DefaultModal";
import ShowContainer from "../../mui/ShowContainer";

const ENUM_LOGIN = {
  login: "login",
  register: "register"
};

export default function LoginModal({ isOpen, closeModal, data: dataLogin, hideCross }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startR] = useLocalStorage("start_r", "");
  const [pbParams] = useLocalStorage("pb_params ", "");
  const lang = useSelector((state) => state.common.lang);
  const isTgBot = useSelector((state) => state.common.isTgBot);
  const telegramWidget = useSelector((state) => state.common.telegramWidget);
  const botName = useSelector((state) => state.common.botName);

  const [typeLogin, setTypeLogin] = useState(ENUM_LOGIN.login);
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const captchaRef = useRef();

  const typeOptions = [
    { label: t("Login by Email"), value: ENUM_LOGIN.login },
    { label: t("Registration by Email"), value: ENUM_LOGIN.register }
  ];

  const onComeIn = async (e, field) => {
    e.preventDefault();
    console.log("typeLogin", typeLogin);
    setBtnLoading(true);

    const info = {
      email: field.email,
      password: field.password,
      captcha: captchaRef.current?.getValue?.()
    };

    if (typeLogin === ENUM_LOGIN.register) {
      const domain = window.location.hostname;

      info.domain = domain;
      info.start_r = startR;
      info.pb_params = pbParams;
    }

    setDisableBtn(true);
    await API_USER.login
      .email(info)
      .then((e) => {
        const data = e.data;
        const error = data.error;
        if (DEBUG) console.log("loginEmail res", e);
        if (error) return errorToast(error);
        dispatch(setUserId(data.user_id));
        successToast(t("Successfully"));
        closeModal(true);
        dataLogin?.cb?.();

        dispatch(setInfo({ nickname: field.email }));
      })
      .catch((e) => {
        if (DEBUG) console.log("catch loginEmail", e);
        errorToast(e || "unknown error");
      });
    captchaRef.current?.reset();
    setDisableBtn(false);
    setBtnLoading(false);
  };

  return (
    <DefaultModal
      classModal="login-modal"
      isOpen={isOpen}
      closeModal={closeModal}
      hideClose={hideCross}>
      <h2>
        {t("Login")} <span className="login-modal-or">{t("or")}</span> {t("Register")}
      </h2>
      <ShowContainer condition={telegramWidget && botName}>
        <span className="login__tg__title">{t("Registration or Login via Telegram")}</span>
      </ShowContainer>
      <div className="login__auth">
        <AuthTg successCb={() => dataLogin?.cb?.()} closeModal={closeModal} />
      </div>
      <ShowContainer condition={!isTgBot}>
        <Segmented
          className="login__segment custom-segment"
          value={typeLogin}
          options={typeOptions}
          onChange={(value) => setTypeLogin(value)}
        />
        <CusForm onSubmit={onComeIn} className={"login__form"}>
          <CusInput
            name={"email"}
            placeholder={"Email"}
            required
            iconJSXstart={<IconMail />}
            dataType={"email"}
          />
          <CusInput
            name={"password"}
            placeholder={"Password"}
            type={"password"}
            required
            minLength={8}
            iconJSXstart={<IconLook />}
          />
          <ReCAPTCHA ref={captchaRef} hl={lang} sitekey={SITE_KEY} />
          <CusButton
            text={"To come in"}
            type={"submit"}
            disable={disableBtn}
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </CusForm>{" "}
      </ShowContainer>
    </DefaultModal>
  );
}
