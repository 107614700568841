export const TEST_MODE =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("192.168.") ||
  window.location.hostname.startsWith("10.");

export const API_SERVER = "devf.securebet.xyz";
export const SITE_KEY = "6LdFOfMpAAAAAPvA5gKu67QgmlU5ScIop0LHLI-8";
export const DEBUG = true;

//var

export const MOBILE_SIZE = 670;
export const MOBILE_HEADER = 1220;

// common
export const ENUM_MODE_GAME = {
  real: "real",
  demo: "demo"
};

export const ENUM_BOT_THEME = {
  telegram: "telegram"
};

export const ENUM_MADE_ON = {
  tgBot: "bot",
  site: "site"
};

export const checkUrlBotTg = () => window.location.search.search("bot_theme=telegram") >= 0;

// deviceSize

export const EnumDeviceModes = {
  mobile: "mobile",
  desktop: "desktop"
};
export const ENUM_DEVICE_ORIENTATION = {
  portrait: "portrait",
  landscape: "landscape"
};

// user

export const defaultUser = {
  firstName: "",
  lastName: "",
  photoUrl: "",
  username: ""
};

// other
export const ONE_PORTION = 6;
export const GAME_COUNT = ONE_PORTION * 4;
