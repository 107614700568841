import React from "react";

export default function IconRightMenu() {
  return (
    <svg
      width="30.000000"
      height="30.000000"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip2001_1682">
          <rect
            id="bx:menu-alt-left"
            width="30.000000"
            height="30.000000"
            transform="matrix(-1 0 0 1 30 0)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="bx:menu-alt-left"
        width="30.000000"
        height="30.000000"
        transform="matrix(-1 0 0 1 30 0)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip2001_1682)">
        <path
          id="Vector"
          d="M25 13.75L10 13.75L10 16.25L25 16.25L25 13.75ZM25 5.5L15 5.5L5 5.5L5 8L25 8L25 5.5ZM25 24.5L15.95 24.5L15.95 22L25 22L25 24.5Z"
          fill="#7A7B90"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
