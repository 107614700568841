import React, { useMemo } from "react";
import "./catalog-game.scss";
import GameDesk from "./games-desk/GameDesk";

import { useSelector } from "react-redux";
import { getSliceForCorusel } from "../../../utils/utils";

export default function CatalogGame() {
  const gamesBonusS = useSelector((state) => state.games.bonusGames);
  const gamesHitS = useSelector((state) => state.games.hitGames);
  const allGamesS = useSelector((state) => state.games.providersGames);
  const historyS = useSelector((state) => state.games.runningGames);

  const gamesBonus = useMemo(() => getSliceForCorusel(gamesBonusS) || [], [gamesBonusS]);
  const gamesHit = useMemo(() => getSliceForCorusel(gamesHitS) || [], [gamesHitS]);
  const allGames = useMemo(() => getSliceForCorusel(allGamesS) || [], [allGamesS]);
  const history = useMemo(() => getSliceForCorusel(historyS) || [], [historyS]);

  return (
    <section className="catalog-game">
      <div className="catalog-game__content">
        <GameDesk showAllLink={"/games/hit"} title={"Hit"} items={gamesHit} />
        <GameDesk
          hideFavorites
          gameNavigate="bonus_game"
          showAllLink={"/games/bonus"}
          title={"Bonus game"}
          items={gamesBonus}
        />
        <GameDesk showAllLink={"/games/all"} title={"All Games"} items={allGames} />
        <GameDesk showAllLink={"/games/history"} title={"History"} items={history} />
        <GameDesk showAllLink={"/games/freespin"} title={"Free spins"} />
      </div>
    </section>
  );
}
