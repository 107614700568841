import { GAME_COUNT } from "../store/enums";

export const sleep = async (ms) => new Promise((rs) => setTimeout(rs, ms));

/**
  @description  получение языка из локалсторадже или куки
  @type () => string<en | ru> 
 */
export const getLang = () => {
  try {
    const urlLang = getParamFromUrl("lang");
    if (urlLang) return urlLang;

    const configLang = getCookie("language");
    const serverLang = configLang?.substring(0, 2)?.toLowerCase?.();
    if (serverLang) return serverLang;

    const lang = JSON.parse(localStorage.getItem("lang"));
    if (!lang) throw new Error("no lang");
    return JSON.parse(localStorage.getItem("lang"));
  } catch {
    const langBrowserFull = navigator.language || navigator?.userLanguage;
    const langMiniBrowser = langBrowserFull.substring(0, 2)?.toLowerCase();
    const langBrowser = langMiniBrowser || "en";
    return langBrowser;
  }
};

/**
  @description получение параметров из url
  @type ( name: string ) => string | null
 */
export const getParamFromUrl = (name) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(name);
};

/**
  @description получение uuid
  @type (  ) => string 
 */
export const getUuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

// setCookie - установка в куки
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// getCookie - получение куки
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// removeDuplicates - удаление дубликатов из массива объектов по ИД
export const removeDuplicates = (arr, key = "id") =>
  arr.filter((obj, idx, arr) => idx === arr.findIndex((t) => t[key] === obj[key]));

export const checkObject = (x) => typeof x === "object" && !Array.isArray(x) && x !== null;

// messageByCode - получение текста по коду
export const messageByCode = (code) => {
  if (code == 500) return "Currency does not match the user's current currency";
  if (code == 501) return "Invalid top-up amount";
  if (code == 502) return "Amount is less than the minimum allowable";
  if (code == 503) return "The amount exceeds the maximum allowable";
  if (code == 504) return "No suitable crypto address found";
  if (code == 505) return "Invalid withdrawal amount";
  if (code == 506) return "Withdrawal amount is less than the minimum allowable";
  if (code == 507) return "Incorrect bank card number";
  if (code == 508) return "Wrong phone number";
  if (code == 509) return "The amount exceeds the user's balance";
  if (code == 510) return "Invalid crypto address";
  if (code == 511)
    return "The promotional code cannot be used (expired, credited to someone else, etc.)";
  return "An error has occurred";
};

// cancelFs - выход из режима фул скрина
export const cancelFs = async (i = 0) => {
  try {
    if (i == 0) document.exitFullscreen();
    if (i == 1) document.webkitExitFullscreen();
    if (i == 2) document.mozCancelFullScreen();
    if (i == 3) document.msExitFullscreen();
  } catch (e) {
    console.log(e);
    cancelFs(i + 1);
  }
};

export const getSliceForCorusel = (games) => {
  return games?.slice(0, GAME_COUNT);

  // for (let i = 0; i < GAME_COUNT / ONE_PORTION; i++) {
  //   const count = GAME_COUNT - ONE_PORTION * i;
  //   if (games.length > count) return games?.slice(0, count);
  //   if (games.length < ONE_PORTION) return games;
  // }
};
