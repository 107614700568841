import React from "react";
import ShowContainer from "../../../mui/ShowContainer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./menu.scss";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import useMenu from "../../../../hooks/useMenu";

export default function Menu() {
  const { t } = useTranslation();
  const { isMobileSize } = useDeviceSize();

  const isTgBot = useSelector((state) => state.common.isTgBot);
  const isAuth = useSelector((state) => state.user.isAuth);

  const { menuList } = useMenu();

  return (
    <>
      <ShowContainer condition={!isTgBot && !(isAuth && isMobileSize)}>
        <div className="menu">
          <div className="menu__content">
            <ShowContainer condition={isAuth && !isMobileSize}>
              <nav className="menu__nav">
                {menuList.map((el, indx) => (
                  <button className="menu__btn" key={indx} onClick={el.onClick}>
                    {t(el.text)}
                  </button>
                ))}
              </nav>
            </ShowContainer>
            {/* <div className="menu__switch-mode">
              <SwitchMode />
            </div> */}
          </div>
        </div>
      </ShowContainer>
    </>
  );
}
