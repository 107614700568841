import React, { useMemo } from "react";
import "./banners.scss";

import BannerItem from "./banner_item/BannerItem";
import { useSelector } from "react-redux";
import ShowContainer from "../../mui/ShowContainer";
import ReactOwlCarousel from "react-owl-carousel";

export default function Banners() {
  const bannersS = useSelector((state) => state.common.banners);
  const lang = useSelector((state) => state.common.lang);
  const bannerPostion = useSelector((state) => state.site.bannerPostion);
  const banners = useMemo(() => bannersS.filter((el) => el.language === lang), [bannersS, lang]);

  return (
    <ShowContainer condition={banners.length}>
      <section className="banners">
        {banners.length > 1 ? (
          <ReactOwlCarousel
            autoplay
            autoplaySpeed={500}
            loop
            className="banners__carousel"
            dots={true}
            nav={false}
            draggable={true}
            startPosition={bannerPostion}
            items={1}>
            {banners.map((el, indx) => (
              <BannerItem key={el.id} {...el} postion={indx} />
            ))}
          </ReactOwlCarousel>
        ) : (
          <div className="banners__carousel ">
            <BannerItem {...banners[0]} />
          </div>
        )}
      </section>
    </ShowContainer>
  );
}
