import React from "react";
import IconLoginUser from "../../../mui/JSXIcons/IconLoginUser";
import { useTranslation } from "react-i18next";
import "./login.scss";
import { setModalLogin } from "../../../../store/siteSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const btnColor = useSelector((state) => state.common.btnColor);

  const style = {
    background: `${btnColor} linear-gradient(0deg, ${btnColor} 10%,  #ffffff80 90%`
  };
  // const style = { background: `${btnColor}` };

  return (
    <button style={style} className="login-btn" onClick={() => dispatch(setModalLogin(true))}>
      <IconLoginUser />
      <span className="login-btn__text">{t("Login / Registration")}</span>
    </button>
  );
}
