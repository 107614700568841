import React from "react";

export default function IconLoginUser() {
  return (
    <svg
      width="33.333313"
      height="33.333496"
      viewBox="0 0 33.3333 33.3335"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M16.66 0C7.46 0 0 7.46 0 16.66C0 25.87 7.46 33.33 16.66 33.33C25.87 33.33 33.33 25.87 33.33 16.66C33.33 7.46 25.87 0 16.66 0ZM10.83 12.5C10.83 11.73 10.98 10.97 11.27 10.26C11.57 9.56 12 8.91 12.54 8.37C13.08 7.83 13.72 7.4 14.43 7.11C15.14 6.81 15.9 6.66 16.66 6.66C17.43 6.66 18.19 6.81 18.89 7.11C19.6 7.4 20.24 7.83 20.79 8.37C21.33 8.91 21.76 9.56 22.05 10.26C22.34 10.97 22.5 11.73 22.5 12.5C22.5 14.04 21.88 15.53 20.79 16.62C19.69 17.71 18.21 18.33 16.66 18.33C15.11 18.33 13.63 17.71 12.54 16.62C11.44 15.53 10.83 14.04 10.83 12.5ZM27.09 24.97C25.84 26.54 24.26 27.81 22.45 28.68C20.65 29.55 18.67 30 16.66 30C14.66 30 12.68 29.55 10.87 28.68C9.07 27.81 7.48 26.54 6.23 24.97C8.93 23.03 12.62 21.66 16.66 21.66C20.7 21.66 24.39 23.03 27.09 24.97Z"
        fill="#000"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}
