import React from "react";

export default function IconFavorite() {
  return (
    <svg
      width="29.000000"
      height="25.999878"
      viewBox="0 0 29 25.9999"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M23.27 2.02C22.44 1.67 21.55 1.5 20.65 1.5C19.75 1.5 18.86 1.67 18.03 2.02C17.2 2.37 16.45 2.88 15.81 3.53L14.49 4.87L13.18 3.53C11.89 2.23 10.15 1.5 8.34 1.5C6.52 1.5 4.78 2.23 3.5 3.53C2.22 4.83 1.5 6.6 1.5 8.44C1.5 10.28 2.22 12.04 3.5 13.34L4.82 14.68L14.49 24.5L24.17 14.68L25.49 13.34C26.13 12.7 26.63 11.93 26.97 11.09C27.32 10.25 27.5 9.35 27.5 8.44C27.5 7.52 27.32 6.62 26.97 5.78C26.63 4.94 26.13 4.17 25.49 3.53C24.85 2.88 24.1 2.37 23.27 2.02Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="3.000000"
        strokeLinejoin="round"
      />
    </svg>
  );
}
