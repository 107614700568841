import React from "react";

export default function IconTgCircle() {
  return (
    <svg
      width="35.000000"
      height="35.000000"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M17.5 0C7.84 0 0 7.83 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.83 27.16 0 17.5 0ZM25.62 11.9C25.35 14.66 24.22 21.38 23.64 24.48C23.39 25.79 22.9 26.23 22.45 26.28C21.43 26.37 20.66 25.62 19.68 24.97C18.14 23.95 17.27 23.32 15.78 22.34C14.05 21.21 15.17 20.58 16.17 19.56C16.43 19.3 20.91 15.22 21 14.85C21.01 14.8 21.01 14.74 20.99 14.68C20.98 14.63 20.95 14.58 20.91 14.54C20.8 14.45 20.66 14.49 20.54 14.5C20.38 14.54 17.93 16.16 13.16 19.38C12.46 19.86 11.83 20.1 11.27 20.08C10.64 20.07 9.45 19.74 8.55 19.44C7.45 19.09 6.59 18.9 6.66 18.28C6.7 17.97 7.14 17.65 7.96 17.32C13.07 15.1 16.46 13.63 18.16 12.93C23.03 10.9 24.02 10.55 24.69 10.55C24.83 10.55 25.16 10.58 25.37 10.76C25.55 10.9 25.6 11.09 25.62 11.23C25.6 11.33 25.63 11.65 25.62 11.9Z"
        fill="#01ABE6"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
