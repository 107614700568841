import React, { useMemo } from "react";
import CusSelect from "../../mui/find_select/CusSelect";
import "./find-game.scss";
import IconLoupe from "../../mui/JSXIcons/IconLoupe";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSaveSiteBeforeGame } from "../../../store/commonSlice";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import useChooseMode from "../../../hooks/useChooseMode";

export default function FindGame() {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const providersGames = useSelector((state) => state.games.providersGames);
  const bonusGames = useSelector((state) => state.games.bonusGames);

  const games = useMemo(() => {
    const allGames = providersGames.map((el) => {
      // if (!el.provider) console.log(el);
      const provider = el.provider || el.providerName;

      return {
        id: el.game_id,
        nameAndProviders: el.name + ` ( ${provider} )`
      };
    });

    bonusGames.forEach((game) => {
      allGames.push({
        id: game.id,
        nameAndProviders: game.name + " ( Bonus game )",
        isBonus: true
      });
    });

    allGames.sort((l, r) => (l.nameAndProviders > r.nameAndProviders ? 1 : -1));
    return allGames;
  }, [bonusGames, providersGames]);

  const gamesOptions = games.map((el) => ({
    label: el.nameAndProviders,
    value: el.id,
    isBonus: el.isBonus
  }));
  const { onChoseMode } = useChooseMode();

  return (
    <div className="find-game">
      <CusSelect
        icon={<IconLoupe />}
        input
        options={gamesOptions}
        placeholder={t("Find a game")}
        onChange={(e) => {
          const item = gamesOptions.find((el) => el.value === e);
          const urlGame = item.isBonus ? "bonus_game" : "game";
          const pathName = window.location.pathname;

          const runGame = () => {
            dispatch(setSaveSiteBeforeGame({ url: pathName, topScroll: window.scrollY }));
            navigate(`/${urlGame}/${e}`);
          };

          onChoseMode({ cbReal: runGame, cbDemo: runGame, game: item });
        }}
      />
    </div>
  );
}
