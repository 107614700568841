import React from "react";
import CarouselDesk from "../../mui/carousel_desk/CarouselDesk";
import "./providers.scss";

import { useSelector } from "react-redux";
import ShowContainer from "../../mui/ShowContainer";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";

export default function Providers() {
  const providers = useSelector((state) => state.games.providers);
  const navigate = useNavigateWithSearch();

  const checkNoEmptyList = Array.isArray(providers) && providers.length;

  const onProviderClick = (el) => {
    navigate("/games/provider/" + el.name);
  };

  return (
    <ShowContainer condition={checkNoEmptyList}>
      <section className="providers">
        <div className="providers__content">
          <CarouselDesk showAllLink="/games/providers" title={"Game providers"} items={providers}>
            {providers.map((el) => (
              <button key={el.name} className="providers__item" onClick={() => onProviderClick(el)}>
                <img src={el.image} />
              </button>
            ))}
          </CarouselDesk>
        </div>
      </section>
    </ShowContainer>
  );
}
