import React, { useRef } from "react";
import IconArrow from "../JSXIcons/IconArrow";
import "./button-up.scss";
import { useEventListener } from "usehooks-ts";

const SCROLL_BORDER = 1000;

export default function ButtonUp() {
  const btnRef = useRef();

  const onBtnClick = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEventListener("scroll", () => {
    if (!btnRef.current) return;
    const scrollY = window.scrollY;
    const isShow = scrollY > SCROLL_BORDER;

    if (isShow) btnRef.current.classList?.add("shown");
    if (!isShow) btnRef.current.classList?.remove("shown");
  });

  return (
    <button ref={btnRef} className="button-up" onClick={onBtnClick}>
      <IconArrow className="rotate180" />
    </button>
  );
}
