import { useDispatch } from "react-redux";
import { setModalBalance, setPromoCode, setWithdrawal } from "../store/siteSlice";
import { API_USER } from "../api/user";
import { setUserId } from "../store/userSlice";

export default function useMenu(params = { full: false }) {
  const { full } = params;
  const dispatch = useDispatch();

  const onTopUpBalance = () => dispatch(setModalBalance(true));
  const onWithdraw = () => dispatch(setWithdrawal(true));
  const onShowPromoCode = () => dispatch(setPromoCode(true));
  const onExitClick = () => {
    console.log("exit");
    API_USER.logout();
    dispatch(setUserId(0));
  };

  const menuList = [
    { text: "Top up balance", onClick: onTopUpBalance },
    { text: "Withdraw", onClick: onWithdraw },
    { text: "Promo codes", onClick: onShowPromoCode }
  ];

  const itemExit = { text: "Exit", onClick: onExitClick };

  if (full) menuList.push(itemExit);

  return { menuList };
}
