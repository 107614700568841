import React from "react";
import Logo from "./logo/Logo";
import Balance from "./balance/Balance";
import Login from "./login/Login";
import { useSelector } from "react-redux";
import Menu from "./menu/Menu";
import User from "./user/User";
import ShowContainer from "../../mui/ShowContainer";
import ChooseCurrency from "../../mui/choose_currency/ChooseCurrency";
import "./header.scss";
import MobileMenu from "./mobile_menu/MobileMenu";

export default function Header({ hideBtn }) {
  const isAuth = useSelector((state) => state.user.isAuth);
  const isTgBot = useSelector((state) => state.common.isTgBot);
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);

  const tgClass = isTgBot ? "tg" : "";
  const headerClass = isAuth ? "auth" : "no-auth";

  return (
    <>
      <header className={`header ${headerClass}`}>
        <div className="header__content">
          <Logo />

          {isAuth ? (
            <div className={`header__right ${tgClass}`}>
              <div className="header__right__auth">
                <Balance />
                <ShowContainer condition={!isTgBot}>
                  {isMobileSize ? (
                    <MobileMenu />
                  ) : (
                    <>
                      <ChooseCurrency classNameUl="" />
                      <User />
                    </>
                  )}
                </ShowContainer>
              </div>
            </div>
          ) : (
            <ShowContainer condition={!hideBtn}>
              <Login />
            </ShowContainer>
          )}
        </div>
        <Menu />
      </header>
    </>
  );
}
