import React from "react";
import "./main.scss";
import Banners from "../components/landing/banners/Banners";
import CatalogGame from "../components/landing/catalog_game/CatalogGame";
import FindGame from "../components/landing/find_game/FindGame";
import Providers from "../components/landing/providers/Providers";
import Favorites from "../components/landing/favorites/Favorites";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import useBalanceUpdate from "../hooks/useBalanceUpdate";

export default function Main() {
  useBalanceUpdate(true);

  return (
    <main className="landing">
      <Header />
      <Banners />
      <FindGame />
      <CatalogGame />
      <Providers />
      <Favorites />
      <Footer />
    </main>
  );
}
