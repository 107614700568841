import React, { useMemo, useRef } from "react";
import ShowContainer from "../ShowContainer.jsx";
import IconArrow from "../JSXIcons/IconArrow.jsx";
import "./carousel-desk.scss";
import { useTranslation } from "react-i18next";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch.js";
import { useSelector } from "react-redux";
import ReactOwlCarousel from "react-owl-carousel";
import TitleUserColor from "../TitleUserColor.jsx";
import useFixCaruselDesk from "../../../hooks/useFixCaruselDesk.js";

export default function CarouselDesk({ items = [], title, children, showAllLink = "" }) {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);
  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const btnColor = useSelector((state) => state.common.btnColor);

  const sliderRef = useRef(null);

  const slidesToShow = useMemo(() => {
    if (isMobileSize) return 2;
    if (isLsm) return 3;
    if (isMobileHeader) return 4;
    return 6;
  }, [isLsm, isMobileSize, isMobileHeader]);

  const manyLength = items.length > slidesToShow;

  const onShowAll = () => {
    if (showAllLink) navigate(`${showAllLink}`);
  };

  const onPrevClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.prev();
    }
  };

  const onNextClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.next();
    }
  };

  useFixCaruselDesk({ sliderRef, children });

  return (
    <ShowContainer condition={items.length}>
      <div className="carousel-desk">
        <div className="carousel-desk__header">
          <TitleUserColor title={t(title)} level={3} />
          <div className="carousel-desk__header__right">
            <button
              style={{
                borderColor: btnColor,
                color: btnColor
              }}
              className="show-all"
              onClick={onShowAll}>
              {t("Show all")}
            </button>
            <div className="carousel-desk__controls">
              <button
                disabled={!manyLength}
                className="carousel-desk__controls__prev"
                onClick={onPrevClick}>
                <IconArrow className="rotate90" />
              </button>
              <button
                disabled={!manyLength}
                className="carousel-desk__controls__next"
                onClick={onNextClick}>
                <IconArrow className="rotate270" />
              </button>
            </div>
          </div>
        </div>
        {manyLength ? (
          <ReactOwlCarousel
            ref={sliderRef}
            startPosition={0}
            className="carousel-desk__items"
            margin={10}
            loop
            responsiveRefreshRate={0}
            mouseDrag={true}
            dots={false}
            items={slidesToShow}>
            {React.Children.map(children, (child, index) => (
              <div key={index} id={`item-${index}`} className="carousel-item" data-index={index}>
                {child}
              </div>
            ))}
          </ReactOwlCarousel>
        ) : (
          <div className={`carousel-desk__items carousel-plug carousel-plug-${slidesToShow}`}>
            {children}
          </div>
        )}
      </div>
    </ShowContainer>
  );
}
