import { createSlice } from "@reduxjs/toolkit";

// - основная информация стора о сайте
const siteSlice = createSlice({
  name: "site",
  initialState: {
    bannerPostion: 0,
    modals: {
      login: false,
      balance: false,
      withdrawal: false,
      promoCode: false,
      chooseMode: false,
      bonusWagering: false,
      data: {
        chooseMode: {
          game: {},
          cbReal: () => {},
          cbDemo: () => {},
          close: () => {}
        },
        login: {
          cb: () => {}
        }
      }
    }
  },
  reducers: {
    setModalLogin(state, newAction) {
      state.modals.login = newAction.payload;
    },
    setModalBalance(state, newAction) {
      state.modals.balance = newAction.payload;
    },
    setWithdrawal(state, newAction) {
      state.modals.withdrawal = newAction.payload;
    },
    setPromoCode(state, newAction) {
      state.modals.promoCode = newAction.payload;
    },
    setChooseMode(state, newAction) {
      state.modals.chooseMode = newAction.payload;
    },
    setDataChooseMode(state, newAction) {
      state.modals.data.chooseMode = newAction.payload;
    },
    setDataLogin(state, newAction) {
      state.modals.data.login = newAction.payload;
    },
    setBonusWagering(state, newAction) {
      state.modals.bonusWagering = newAction.payload;
    },
    setBannerPostion(state, newAction) {
      state.bannerPostion = newAction.payload;
    }
  }
});

export default siteSlice.reducer;

export const {
  setModalLogin,
  setModalBalance,
  setWithdrawal,
  setPromoCode,
  setChooseMode,
  setDataChooseMode,
  setDataLogin,
  setBonusWagering,
  setBannerPostion
} = siteSlice.actions;
