import React from "react";
import useScrollUp from "../../../hooks/useScrollUp";
import Header from "../../landing/header/Header";
import Back from "../../mui/back/Back";
import Footer from "../../landing/footer/Footer";
import ButtonUp from "../../mui/button_up/ButtonUp";
import FindGame from "../../landing/find_game/FindGame";

export default function GamesContainer({ children, title, backLink = "/" }) {
  useScrollUp();

  return (
    <main className="games">
      <Header />
      <FindGame />
      <section className="games__content">
        <div className="games__content__content">
          <Back to={backLink} title={title} />
          {children}
        </div>
      </section>
      <ButtonUp />
      <Footer />
    </main>
  );
}
