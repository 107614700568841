import React, { useState } from "react";
import "./mobile-menu.scss";
import IconRightMenu from "../../../mui/JSXIcons/IconRightMenu";
import ShowContainer from "../../../mui/ShowContainer";
import User from "../user/User";
import { useTranslation } from "react-i18next";
import ChooseCurrency from "../../../mui/choose_currency/ChooseCurrency";
import useMenu from "../../../../hooks/useMenu";
import Balance, { ENUM_VIEW } from "../balance/Balance";

export default function MobileMenu() {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const { menuList } = useMenu({ full: true });

  return (
    <div className="mobile-menu">
      <button className="mobile-menu__btn" onClick={() => setOpenMenu((prev) => !prev)}>
        <IconRightMenu />
      </button>

      <ShowContainer condition={openMenu}>
        <div className="mobile-menu__menu">
          <User hideMenu />
          <Balance propsView={ENUM_VIEW.desctop} showWager />
          <div className="menu__currency">
            <span>{t("Currency")}</span>
            <ChooseCurrency />
          </div>
          <div className="menu__border" />

          {menuList.map((el, id) => (
            <button key={id} onClick={el.onClick}>
              {t(el.text)}
            </button>
          ))}

          <div className="menu__border" />
          {/* <SwitchMode /> */}
        </div>
      </ShowContainer>
    </div>
  );
}
