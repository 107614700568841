import React from "react";
import TermsEn from "../components/terms/TermsEn";
import TermsRu from "../components/terms/TermsRu";
import "./terms.scss";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import { useSelector } from "react-redux";
import useScrollUp from "../hooks/useScrollUp";
import ButtonUp from "../components/mui/button_up/ButtonUp";

export default function Terms() {
  useScrollUp();
  const lang = useSelector((state) => state.common.lang);

  return (
    <section className="terms">
      <Header hideBtn />
      <div className="terms__content">{lang === "en" ? <TermsEn /> : <TermsRu />}</div> <ButtonUp />
      <Footer />
    </section>
  );
}
