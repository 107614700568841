import React from "react";

export default function IconMail() {
  return (
    <svg
      width="26.666504"
      height="21.333496"
      viewBox="0 0 26.6665 21.3335"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M26.66 2.66C26.66 1.2 25.46 0 24 0L2.66 0C1.2 0 0 1.2 0 2.66L0 18.66C0 20.13 1.2 21.33 2.66 21.33L24 21.33C25.46 21.33 26.66 20.13 26.66 18.66L26.66 2.66ZM24 2.66L13.33 9.31L2.66 2.66L24 2.66ZM24 18.66L2.66 18.66L2.66 5.33L13.33 12L24 5.33L24 18.66Z"
        fill="#7A7B90"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
