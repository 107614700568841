import React from "react";
import "./user.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { API_USER } from "../../../../api/user";
import { setUserId, setInfo } from "../../../../store/userSlice";
import ShowContainer from "../../../mui/ShowContainer";
import { defaultUser } from "../../../../store/enums";

export default function User({ hideMenu }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nickname = useSelector((state) => state.user.info.nickname) || "Nickname";
  const imgSource = useSelector((state) => state.user.info.photoUrl);

  const onExit = async () => {
    console.log("exit");
    await API_USER.logout();
    dispatch(setUserId(0));
    dispatch(setInfo(defaultUser));
  };

  return (
    <div className="user">
      {imgSource ? (
        <img className="user__img" src={imgSource} width={50} />
      ) : (
        <div className="user__img">
          <span className="user__img__word">{nickname?.[0]}</span>
        </div>
      )}
      <span className="user__nickname">{nickname}</span>

      <ShowContainer condition={!hideMenu}>
        <div className="user-hover__menu">
          <button onClick={onExit}>{t("Exit")}</button>
        </div>
      </ShowContainer>
    </div>
  );
}
